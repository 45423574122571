.text-gradient-yellow {
  background: linear-gradient(108.26deg, #E5DB85 0%, #85600F 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-gradient-yellow {
  background: linear-gradient(108.26deg, #E5DB85 0%, #85600F 100%);
}

.fill-gradient-yellow {
  fill: url(#textGradient);
}

.border-gradient-yellow {
  background: 
    linear-gradient(#ffffff 0 0) padding-box, /*this is your grey background*/
    linear-gradient(108.26deg, #E5DB85 0%, #85600F 100%) border-box;
  border: 2px solid transparent;
}

.border-b-gradient-yellow {
  background: 
    linear-gradient(#ffffff 0 0) padding-box, /*this is your grey background*/
    linear-gradient(108.26deg, #E5DB85 0%, #85600F 100%) border-box;
  border-bottom: 2px solid transparent !important;
}

input:focus {
  outline: none; /* Прибирає фокусну рамку */
}

.card-shadow {
  box-shadow: 0px 2px 4px 0px #00000026;
}

.elips-shadow {
  box-shadow: 0px 5px 16px 0px #080F340F;
}

.navbar-shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.047);
}

.flex-col-m {
  @apply flex-row;
}

@media (max-width: 350px) {
  .flex-col-m {
    @apply flex-wrap w-full justify-between space-x-0;
  }

  .w-full-m { 
    @apply w-full;
  }
  
}



@media (max-width: 640px) {
  .border-dotted-section {
    border-style: dotted;
    
    border-bottom-width: 3px; 
  }

}

.recapcha {
  @apply my-2;
  @media (max-width: 400px) {
    iframe {
      width: 200px !important;
    }
  }

  @media (max-width: 210px) {
    iframe {
      width: 150px !important;
    }
  }
}