@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 300px) {
  h1, h2, h3, p, span, q, div {
    word-wrap: break-word;
  }
}

h1, h2, h3, p, span, q, div {
  -webkit-tap-highlight-color: transparent; /* For Safari, Chrome, Opera */
  -moz-tap-highlight-color: transparent; /* For Firefox */
  -ms-tap-highlight-color: transparent; /* For IE, Edge */
  white-space: pre-wrap;
}


img {
  -webkit-user-drag: none; /* Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}


* {
  -webkit-tap-highlight-color: transparent; /* For Safari, Chrome, Opera */
  -moz-tap-highlight-color: transparent; /* For Firefox */
  -ms-tap-highlight-color: transparent; /* For IE, Edge */
}


