a.effect-box:after,
a.effect-box:before {
  content: '';
  position: absolute;
  left: 0;
  display: inline-block;
  height: 100%;
  width: 100%;
  margin-top: 2px;
  opacity: 0;
  -webkit-transition: opacity 0.55s, -webkit-transform 0.55s;
  transition: opacity 0.55s, transform 0.55s;
  
}

a.effect-box:before {
  border-left: 1px solid;
  border-right: 1px solid;
  -webkit-transform: scale(1,0);
  transform: scale(1,0);
}

a.effect-box:after {
  border-bottom: 1px solid;
  border-top: 1px solid;
  -webkit-transform: scale(0,1);
  transform: scale(0,1);
}

a.effect-box:hover:after,
a.effect-box:hover:before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
