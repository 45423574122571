@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@100..900&family=Inter:wght@100..900&family=Lemon&family=Montserrat:wght@500&family=Oswald:wght@200..700&family=Roboto&family=Tektur&display=swap');

.font-oswald {
  font-family: "Oswald", sans-serif;
}

.font-inter {
  font-family: "Inter", sans-serif;
}

.font-dm-sans {
  font-family: "DM Sans", sans-serif;
}